"use client";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuViewport,
} from "@/components/ui/navigation-menu";
import { Link, usePathname } from "@/lib/i18n";
import Logo from "./shared/Logo";
import { ModeToggle } from "./shared/DarkModeSwitch";
import { TopSearchBox } from "./searchBox/TopSearchBox";
import { cn } from "@/lib/utils";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { languageTag } from "@/paraglide/runtime";
import { useI18n } from "./useI18n";
import * as m from "@/paraglide/messages.js";
import { SiteSetting } from "./SiteSetting";
import { UserNav } from "./panel/UserNav";
import { Settings } from "lucide-react";
import { userDataAtom } from "./atoms/userAtom";
import { useAtom } from "jotai";
import { useSiteLanguage } from "./atoms/siteLanguage";

export const Nav = () => {
  // detect site language
  useSiteLanguage();

  const { isRTL, fontClassName } = useI18n();
  const locale = languageTag();
  const path = usePathname();
  const isHome = path === "/" || path === `/${locale}`;
  const isSearch =
    path.startsWith("/search") || path.startsWith(`/${locale}/search`);
  const page = isHome ? "home" : "secondary";

  const showSearchBar = !isSearch && !isHome;

  const [userData] = useAtom(userDataAtom);

  return (
    <div style={{ direction: isRTL ? "rtl" : "ltr" }}>
      <div
        className={`flex items-center justify-between width-full ${fontClassName}`}
        style={{
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <NavigationMenu
          className="flex items-end justify-start width-full flex-grow"
          style={{ direction: isRTL ? "rtl" : "ltr", zIndex: 110 }}
        >
          <NavigationMenuItem className="list-none" style={{ marginRight: 30 }}>
            <Link href="/" legacyBehavior passHref>
              <Logo />
            </Link>
          </NavigationMenuItem>
          {showSearchBar && (
            <li className="list-none flex-grow mr-5 ml-5 hidden sm:block">
              <TopSearchBox style={{ marginLeft: 0 }} />
            </li>
          )}
          {!showSearchBar && (
            <NavigationMenuList
              style={{ marginTop: 20, direction: isRTL ? "rtl" : "ltr" }}
            >
              <NavigationMenuItem className="bg-transparent">
                <Link href="/latest" legacyBehavior passHref>
                  <NavigationMenuLink
                    className={navigationMenuTriggerStyle({
                      page,
                      showOnlyLargeScreen: true,
                    })}
                  >
                    {m.menu_latest()}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="/trends" legacyBehavior passHref>
                  <NavigationMenuLink
                    className={navigationMenuTriggerStyle({
                      page,
                      showOnlyLargeScreen: true,
                    })}
                  >
                    {m.menu_trends()}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="/plugins" legacyBehavior passHref>
                  <NavigationMenuLink
                    className={navigationMenuTriggerStyle({
                      page,
                      showOnlyLargeScreen: true,
                    })}
                  >
                    {m.menu_plugins()}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="/blog" legacyBehavior passHref>
                  <NavigationMenuLink
                    className={navigationMenuTriggerStyle({
                      page,
                      showOnlyLargeScreen: true,
                    })}
                  >
                    {m.menu_blog()}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="https://t.me/subdl_com" legacyBehavior passHref>
                  <NavigationMenuLink
                    className={navigationMenuTriggerStyle({
                      page,
                      showOnlyLargeScreen: true,
                    })}
                  >
                    {m.menu_community()}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          )}
        </NavigationMenu>
        {/* login  &  register  */}
        <NavigationMenu
          className="flex items-center flex-grow-0"
          style={{
            marginTop: 20,
            direction: isRTL ? "rtl" : "ltr",
            zIndex: 150,
          }}
        >
          <NavigationMenuList
            style={{
              direction: isRTL ? "rtl" : "ltr",
              zIndex: 150,
              position: "relative",
            }}
          >
            <MainMenuDropDown page={page} showSearchBar={showSearchBar} />
            {/* for login & register */}
            {/* <NavigationMenuItem className="bg-transparent">
              <Link href="/docs" legacyBehavior passHref>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle({ page })}
                >
                  Join
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem> */}
            {!userData?.token ? (
              <NavigationMenuItem>
                <Link href="/panel/register" legacyBehavior passHref>
                  <NavigationMenuLink
                    className={navigationMenuTriggerStyle({
                      page,
                    })}
                  >
                    {m.menu_join()}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            ) : null}

            <NavigationMenuItem>
              <SiteSetting />
            </NavigationMenuItem>
            {userData?.token ? (
              <NavigationMenuItem>
                <UserNav />
              </NavigationMenuItem>
            ) : null}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      {!isHome && (
        <div
          className={cn(
            `container mx-auto px-5 py-10`,
            fontClassName,
            isRTL ? "rtl" : "ltr",
            isSearch ? "block" : "block sm:hidden"
          )}
        >
          <TopSearchBox />
        </div>
      )}
    </div>
  );
};

export function MainMenuDropDown({
  page,
  showSearchBar,
}: {
  page: "home" | "secondary";
  showSearchBar: boolean;
}) {
  const { fontClassName, isRTL } = useI18n();
  const components: { title: string; href: string }[] = [
    {
      title: m.menu_latest(),
      href: "/latest",
    },
    {
      title: m.menu_trends(),
      href: "/trends",
    },
    {
      title: m.menu_plugins(),
      href: "/plugins",
    },
    {
      title: m.menu_blog(),
      href: "/blog",
    },
    {
      title: m.menu_community(),
      href: "https://t.me/subdl_com",
    },
  ];

  return (
    <>
      <NavigationMenuItem>
        <NavigationMenuTrigger
          className={navigationMenuTriggerStyle({
            page,
            showOnlySmallScreen: showSearchBar ? false : true,
          })}
        >
          {m.menu_menu()}
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul
            className={cn(
              `grid w-[200px] gap-1 p-2 md:w-[200px] md:grid-cols-1 lg:w-[200px]`,
              fontClassName,
              isRTL ? "rtl" : "ltr"
            )}
            style={{ zIndex: 150 }}
          >
            {components.map((component) => (
              <ListItem
                key={component.title}
                title={component.title}
                href={component.href}
                style={{
                  direction: isRTL ? "rtl" : "ltr",
                  textAlign: isRTL ? "right" : "left",
                }}
              ></ListItem>
            ))}
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </>
  );
}

const ListItem = forwardRef<ElementRef<"a">, ComponentPropsWithoutRef<"a">>(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            href={props.href || "#"}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";
