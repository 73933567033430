import { useAtom } from "jotai";
import { userDataAtom } from "../atoms/userAtom";
import { usePathname, useRouter } from "@/lib/i18n";
import { useToast } from "../ui/use-toast";
import * as m from "@/paraglide/messages.js";

export const useLogout = () => {
  const { toast } = useToast();
  const [userData, setUserData] = useAtom(userDataAtom);
  const router = useRouter();
  const currentPath = usePathname();

  const doLogout = async () => {
    if (!userData?.token) {
      return;
    }
    setUserData({
      token: undefined,
      username: undefined,
      scStepCode: undefined,
      scVerified: undefined,
      scUsername: undefined,
      bio: undefined,
      profile: undefined,
      country: undefined,
      name: undefined,
    });

    toast({
      title: m.logout_title(),
      description: m.logout_description(),
      variant: "default",
    });

    // redirect if user is in panel
    if (currentPath.startsWith("/panel")) {
      router.push("/panel/login");
    }
  };

  return {
    doLogout,
  };
};
