import { useAtom } from "jotai";
import { userDataAtom } from "../atoms/userAtom";
import { config } from "@/config";
import { useCallback, useEffect } from "react";

export const useGetUserInfo = () => {
  const [userData, setUserData] = useAtom(userDataAtom);

  const getUserInfo = useCallback(async () => {
    if (!userData) return;

    const result = await fetch(`${config.apiUrl}/user/profileInfo`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData?.token}`,
      },
    });
    const data = await result.json();
    setUserData({
      ...userData,
      profile: data.profile,
      bio: data.bio,
    });
    console.log(data);
  }, [setUserData, userData]);

  useEffect(() => {
    if (userData?.token && userData?.profile === undefined) {
      getUserInfo();
    }
  }, [getUserInfo, userData]);

  return {
    userData,
    getUserInfo,
  };
};
