"use client";

import { useCallback, useState } from "react";
import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Languages, Settings, Settings2 } from "lucide-react";
import { useTheme } from "next-themes";
import * as m from "@/paraglide/messages.js";
import { useI18n } from "./useI18n";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Badge } from "./ui/badge";
import { Link, usePathname } from "@/lib/i18n";
import { SiteLanguages } from "./SiteLanguages";
import { useAtom } from "jotai";
import { languageBoxAtom } from "./languageBox/LanguageBoxComp";
import { languagesAtoms } from "./atoms/languageAtom";

type Checked = DropdownMenuCheckboxItemProps["checked"];

export function SiteSetting() {
  const { isRTL, fontClassName } = useI18n();
  const { setTheme, theme } = useTheme();

  const [isOpen, setIsOpen] = useAtom(languageBoxAtom);
  const [filteredLanguages] = useAtom(languagesAtoms);

  const toggleLangBox = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const selectedLangs = filteredLanguages.length;

  return (
    <Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {/* <Button variant="outline">
            <Settings2 className="h-4 w-4" />
          </Button> */}
          <Button variant="link">
            <Settings />
          </Button>
          {/* <div className="flex items-center mr-3 text-white ">
            <Settings />
          </div> */}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={`w-30 ${fontClassName} ${isRTL ? "rtl" : "ltr"}`}
          style={{ direction: isRTL ? "rtl" : "ltr", zIndex: 150 }}
        >
          <DropdownMenuLabel>{m.setting_menu_title()}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
          >
            <div className="flex items-center gap-2">
              {theme === "dark" ? (
                <MoonIcon className=" h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
              ) : (
                <SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              )}
              {theme === "dark"
                ? m.setting_menu_dark_theme()
                : m.setting_menu_light_theme()}
            </div>
          </DropdownMenuItem>
          {/* <DropdownMenuItem onClick={() => {}}>
            <div className="flex items-center gap-2">
              <Languages className="h-[1.2rem] w-[1.2rem]" />
              {m.setting_menu_site_language()}
            </div>
          </DropdownMenuItem> */}
          <DropdownMenuItem>
            <DialogTrigger asChild>
              <div className="flex items-center gap-2">
                <Languages className="h-[1.2rem] w-[1.2rem]" />
                {m.setting_menu_site_language()}
              </div>
            </DialogTrigger>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              toggleLangBox();
            }}
          >
            <div className="flex items-center gap-2">
              <Settings2 className="h-[1.2rem] w-[1.2rem]" />
              {selectedLangs === 0 ? (
                m.search_setting_selected()
              ) : (
                <div className="flex gap-1">
                  <Badge>{selectedLangs}</Badge>
                  {m.search_setting_has_selected_language()}
                </div>
              )}
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Site Language</DialogTitle>
        </DialogHeader>
        <SiteLanguages />
      </DialogContent>
    </Dialog>
  );
}
