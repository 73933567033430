"use client";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useI18n } from "../useI18n";
import * as m from "@/paraglide/messages.js";
import {
  FileStack,
  LogOut,
  SendIcon,
  SquareUserRound,
  Workflow,
} from "lucide-react";
import { DashboardIcon } from "@radix-ui/react-icons";
import { useRouter } from "@/lib/i18n";
import { useGetUserInfo } from "./useGetUserInfo";
import { useLogout } from "./useLogout";

export function UserNav() {
  const router = useRouter();
  const { isRTL, fontClassName } = useI18n();
  const { userData } = useGetUserInfo();
  const { doLogout } = useLogout();

  return (
    <div className="flex items-center gap-2 select-none">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Avatar>
            {userData?.profile ? (
              <AvatarImage
                src={userData.profile}
                alt={userData.name}
                className="h-10 w-10"
              />
            ) : (
              <AvatarFallback>{userData?.name || ""}</AvatarFallback>
            )}
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={`w-30 ${fontClassName} ${isRTL ? "rtl" : "ltr"} mr-3`}
          style={{ direction: isRTL ? "rtl" : "ltr", zIndex: 150 }}
        >
          <DropdownMenuItem
            onClick={() => {
              router.push("/panel");
            }}
          >
            <div className="flex items-center gap-2">
              <DashboardIcon className="h-[1rem] w-[1rem]" />
              {m.user_nav_dashboard()}
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              router.push("/panel/upload");
            }}
          >
            <div className="flex items-center gap-2">
              <SendIcon className="h-[1rem] w-[1rem]" />
              {m.user_nav_upload()}
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              router.push("/panel/subtitles?tab=published");
            }}
          >
            <div className="flex items-center gap-2">
              <FileStack className="h-[1rem] w-[1rem]" />
              {m.user_nav_subtitles()}
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              router.push("/panel/account");
            }}
          >
            <div className="flex items-center gap-2">
              <SquareUserRound className="h-[1rem] w-[1rem]" />
              {m.user_nav_profile()}
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              router.push("/panel/api");
            }}
          >
            <div className="flex items-center gap-2">
              <Workflow className="h-[1rem] w-[1rem]" />
              {m.user_nav_api()}
            </div>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              doLogout();
            }}
          >
            <div className="flex items-center gap-2">
              <LogOut className="h-[1rem] w-[1rem]" />
              {m.user_nav_logout()}
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
