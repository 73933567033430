import(/* webpackMode: "eager", webpackExports: ["ClientLanguageProvider"] */ "/app/node_modules/.pnpm/@inlang+paraglide-next@0.5.2_babel-plugin-macros@3.1.0_next@15.0.0-canary.147_@babel+core@7.2_6dvyutud735ddsuzzyzuxnygw4/node_modules/@inlang/paraglide-next/dist/paraglide-next/src/app/providers/ClientLanguageProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/jotai@2.9.2_@types+react@18.3.3_react@18.3.1/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0-canary.147_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0-canary.147_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0-canary.147_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0-canary.147_@babel+core@7.25.2_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\"],\"variable\":\"--font-sans\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/app/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/src/app/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageBoxComp"] */ "/app/src/components/languageBox/LanguageBoxComp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/app/src/components/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Stats"] */ "/app/src/components/stats/Stats.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
