"use client";
import { languagesAtoms } from "@/components/atoms/languageAtom";
import { languageBoxAtom } from "@/components/languageBox/LanguageBoxComp";
import { Badge } from "@/components/ui/badge";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuViewport,
} from "@/components/ui/navigation-menu";
import { useAtom } from "jotai";
import { useCallback } from "react";
import * as m from "@/paraglide/messages.js";
import { useI18n } from "@/components/useI18n";
import { Link, usePathname } from "@/lib/i18n";

export const Footer = () => {
  const { fontClassName, isRTL } = useI18n();
  const path = usePathname();
  const isHome = path === "/";
  const page = isHome ? "home" : "secondary";
  const [isOpen, setIsOpen] = useAtom(languageBoxAtom);
  const [filteredLanguages] = useAtom(languagesAtoms);

  const toggleLangBox = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const selectedLangs = filteredLanguages.length;

  return (
    <footer
      className={`fixed bottom-[5px] left-[35px] right-[35px] w-auto min-h-[20px] flex items-center z-[200] justify-between ${fontClassName}`}
      style={{ direction: isRTL ? "rtl" : "ltr" }}
    >
      <NavigationMenu
        className="flex items-center justify-start"
        style={{ marginTop: 20, direction: isRTL ? "rtl" : "ltr" }}
      >
        <NavigationMenuList style={{ direction: isRTL ? "rtl" : "ltr" }}>
          <NavigationMenuItem
            className="bg-transparent"
            onClick={toggleLangBox}
          >
            <NavigationMenuLink
              className={navigationMenuTriggerStyle({ page })}
            >
              {selectedLangs === 0 ? (
                m.search_setting_selected()
              ) : (
                <div className="flex gap-1">
                  <Badge>{selectedLangs}</Badge>
                  {m.search_setting_has_selected_language()}
                </div>
              )}
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href="/ads" legacyBehavior passHref>
              <NavigationMenuLink
                className={navigationMenuTriggerStyle({ page })}
              >
                {m.footer_menu_ads()}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href="/api-doc" legacyBehavior passHref>
              <NavigationMenuLink
                className={navigationMenuTriggerStyle({ page })}
              >
                {m.footer_menu_api_doc()}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </footer>
  );
};
