"use client";
import { Spacer } from "../lego/Spacer";
import { SearchInput } from "./SearchInput";
import { useI18n } from "../useI18n";
import { usePathname } from "@/lib/i18n";
import { useParams } from "next/navigation";

export const TopSearchBox = ({ style }: { style?: React.CSSProperties }) => {
  const { fontClassName, isRTL, locale } = useI18n();
  const path = usePathname();
  const params = useParams();
  let { type, query } = getType(params);

  // if is search page and no query
  if (!path.startsWith("/search") && !path.startsWith(`/${locale}/search`)) {
    query = "";
  }

  return (
    <div
      className="flex flex-grow"
      style={{
        minWidth: 300,
        width: "auto",
        maxWidth: 600,
        direction: isRTL ? "rtl" : "ltr",
        ...style,
      }}
    >
      <SearchInput query={query} />
    </div>
  );
};

const getType = (params: { slug?: string[] }) => {
  if (!params.slug || params.slug.length === 0) {
    return {
      type: undefined,
      query: "",
    };
  }

  const [typeOrQuery, maybeQuery] = params.slug;

  let type: "movie" | "tv" | undefined;
  let query: string;

  if (typeOrQuery === "movie" || typeOrQuery === "tv") {
    type = typeOrQuery;
    query = maybeQuery || "";
  } else {
    type = undefined;
    query = typeOrQuery;
  }

  return {
    type,
    query: decodeURIComponent(query),
  };
};
