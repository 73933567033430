import { Link, usePathname, useRouter } from "@/lib/i18n";
import { useAtom } from "jotai";
import { siteLanguageAtom } from "./atoms/siteLanguage";
import { cn } from "@/lib/utils";
import { useCallback } from "react";
import type { AvailableLanguageTag } from "@/paraglide/runtime";

export const SiteLanguages = () => {
  const currentPath = usePathname();
  const router = useRouter();

  const [siteLanguage, setSiteLanguage] = useAtom(siteLanguageAtom);

  const changeLanguage = useCallback(
    (languageCode: AvailableLanguageTag) => {
      setSiteLanguage(languageCode);

      router.push(`${currentPath}`, {
        locale: languageCode,
      });
    },
    [currentPath, router, setSiteLanguage]
  );

  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
      {websiteLanguageList.map((language) => (
        <div
          onClick={() => changeLanguage(language.code)}
          key={language.code}
          title={language.nativeName}
          className={cn(
            `hover:bg-secondary hover:text-white rounded-md cursor-pointer`,
            siteLanguage === language.code && "bg-black text-white"
          )}
        >
          <div className="flex items-center gap-2">
            <div className="w-[35px] text-center">{language.flag}</div>
            {language.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export const websiteLanguageList = [
  { code: "ar", name: "Arabic", nativeName: "العربية", flag: "🇸🇦" },
  { code: "de", name: "German", nativeName: "Deutsch", flag: "🇩🇪" },
  { code: "en", name: "English", nativeName: "English", flag: "🇬🇧" },
  { code: "fa", name: "Farsi/Persian", nativeName: "فارسی", flag: "🇮🇷" },
  { code: "fr", name: "French", nativeName: "Français", flag: "🇫🇷" },
  { code: "es", name: "Spanish", nativeName: "Español", flag: "🇪🇸" },
  { code: "hi", name: "Hindi", nativeName: "हिन्दी", flag: "🇮🇳" },
  {
    code: "id",
    name: "Indonesian",
    nativeName: "Bahasa Indonesia",
    flag: "🇮🇩",
  },
  { code: "pt-PT", name: "Portuguese", nativeName: "Português", flag: "🇵🇹" },
] as const;
