import React from "react";

const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={73} height={65} fill="none" {...props}>
    <path fill="#FFEE2B" d="M0 0h73v65H0z" />
    <path
      d="M19.556 41.3h-3.052v-1.848h-3.668V41.3l6.72 7.98v5.488L18.352 56h-7.336l-1.232-1.232v-3.64h3.052v1.82h3.668v-1.82l-6.72-8.008v-5.488l1.232-1.232h7.336l1.204 1.232V41.3zm9.164-4.9h3.052v18.396L30.568 56h-7.364l-1.232-1.204V36.4h3.052v16.548h3.696V36.4zm15.267 1.232v7.364L42.783 46.2l1.204 1.204v7.364L42.783 56h-8.596V36.4h8.596l1.204 1.232zm-3.052 1.848h-3.668v5.516h3.668V39.48zm-3.668 13.44h3.668v-4.9h-3.668v4.9zM55.026 36.4l1.176 1.232v17.136L55.026 56h-8.624V36.4h8.624zM53.15 52.948V39.48h-3.668v13.468h3.668zm8.519 0H67.1V56h-8.484V36.4h3.052v16.548z"
      fill="#000"
    />
  </svg>
);

export default Logo;
