"use client";
import { Wrapper } from "@/components/lego/Wrapper";

import { ReactNode } from "react";
import { languageTag } from "@/paraglide/runtime";
import { usePathname } from "@/lib/i18n";
import { cn } from "@/lib/utils";

export const Header = ({ children }: { children: ReactNode }) => {
  const locale = languageTag();
  const path = usePathname();
  const isHome = path === "/" || path === `/${locale}`;
  const isSearch =
    path.startsWith("/search") || path.startsWith(`/${locale}/search`);

  return (
    <header
      className={cn(
        "w-full",
        isHome ? "h-auto" : "bg-header",
        isSearch || isHome ? "h-[200px]" : "h-[300px] sm:h-[200px]"
      )}
    >
      <Wrapper>{children}</Wrapper>
    </header>
  );
};
