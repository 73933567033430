"use client";
import { atomWithStorage } from "jotai/utils";

type userDataType =
  | {
      email: string;
      token: string;
      country: string;
      name: string;
      username: string;
      scStepCode: string;
      scVerified: boolean;
      scUsername: string;
      bio?: string;
      profile?: string;
    }
  | {
      token: undefined;
      username: undefined;
      scStepCode: undefined;
      scVerified: undefined;
      scUsername: undefined;
      bio: undefined;
      profile: undefined;
      country: undefined;
      name: undefined;
    };

export const userDataAtom = atomWithStorage<userDataType>(
  "userData",
  {
    token: undefined,
    username: undefined,
    scStepCode: undefined,
    scVerified: undefined,
    scUsername: undefined,
    bio: undefined,
    profile: undefined,
    country: undefined,
    name: undefined,
  },
  undefined,
  {
    getOnInit: true,
  }
);
