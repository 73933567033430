import { useCallback, useEffect } from "react";

export const useStats = () => {
  const sendStatsToDb = useCallback((url: string) => {
    try {
      const urlPath = url
        .replace("https://subdl.com", "")
        .replace("https://subdl.net", "");

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "";

      fetch(
        `https://api.subdl.com/statsLog?timeZone=${timeZone}&url=${urlPath}`
      )
        .then(() => {
          console.log("thanks for visiting subdl v2");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const url = window.location.href;
    setTimeout(() => {
      sendStatsToDb(url);
    }, 1000);
  }, [sendStatsToDb]);

  return;
};
